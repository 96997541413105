
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "InterviewResult",
  setup() {
    const answeredQuestions = ref(10); // Cevaplanan soru sayısı
    const totalTime = ref("15 dk 30 sn"); // Toplam süre
    const remainingTime = ref("0 dk 0 sn"); // Kalan süre

    const backToHome = () => {
      // Ana sayfaya yönlendirme işlemi burada yapılabilir
      window.location.href = "/";
    };

    onMounted(() => {
      document.title = "Mülakat Sonuçları"; // Sayfa başlığını ayarla
    });

    return {
      answeredQuestions,
      totalTime,
      remainingTime,
      backToHome,
    };
  },
});
