<template>
  <div class="row g-6">
    <div class="col-xl-4">
      <div class="card shadow-lg" style="width: 700px">
        <div class="card-body d-flex flex-column">
          <!-- Başlık -->
          <div class="text-center mb-4">
            <div class="card-label fw-bolder fs-2">Mülakat Sonuçları</div>
            <span class="text-muted fs-7 mb-4">
              Mülakata katıldığınız için teşekkür ederiz.
            </span>
          </div>

          <!-- Bilgilendirme Mesajı -->
          <div class="card-body p-9 ps-6 pb-1">
            <h4 class="fw-bold text-center mb-3">Teşekkürler!</h4>
            <p class="text-center mb-4 text-muted">
              Mülakat sonucu ve gelişmeleri e-posta üzerinden takip
              edebilirsiniz.
            </p>

            <div class="d-flex flex-wrap flex-center">
              <!--begin::Stats-->
              <div
                class="
                  border border-gray-300 border-dashed
                  rounded
                  py-3
                  px-3
                  mb-3
                "
              >
                <div class="fs-4 fw-bold text-gray-700">
                  <span class="w-75px">{{ answeredQuestions }}</span>
                  <i class="ki-duotone ki-arrow-up fs-3 text-success"
                    ><span class="path1"></span><span class="path2"></span
                  ></i>
                </div>
                <div class="fw-semibold text-muted">Cevaplanan Soru Sayısı</div>
              </div>
              <!--end::Stats-->
              <!--begin::Stats-->
              <div
                class="
                  border border-gray-300 border-dashed
                  rounded
                  py-3
                  px-3
                  mb-3
                  ms-3
                "
              >
                <div class="fs-4 fw-bold text-gray-700">
                  <span class="w-75px">{{ totalTime }}</span>
                  <i class="ki-duotone ki-arrow-up fs-3 text-success"
                    ><span class="path1"></span><span class="path2"></span
                  ></i>
                </div>
                <div class="fw-semibold text-muted">Geçen Süre</div>
              </div>
              <!--end::Stats-->
              <!--begin::Stats-->
              <div
                class="
                  border border-gray-300 border-dashed
                  rounded
                  py-3
                  px-3
                  mb-3
                  ms-3
                "
              >
                <div class="fs-4 fw-bold text-gray-700">
                  <span class="w-75px">{{ remainingTime }}</span>
                  <i class="ki-duotone ki-arrow-up fs-3 text-success"
                    ><span class="path1"></span><span class="path2"></span
                  ></i>
                </div>
                <div class="fw-semibold text-muted">Kalan Süre</div>
              </div>
              <!--end::Stats-->
            </div>

            <!-- Geri Dönüş Butonu -->
            <div class="text-center mt-4">
              <button class="btn btn-primary" @click="backToHome">
                Ana Sayfaya Dön
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "InterviewResult",
  setup() {
    const answeredQuestions = ref(10); // Cevaplanan soru sayısı
    const totalTime = ref("15 dk 30 sn"); // Toplam süre
    const remainingTime = ref("0 dk 0 sn"); // Kalan süre

    const backToHome = () => {
      // Ana sayfaya yönlendirme işlemi burada yapılabilir
      window.location.href = "/";
    };

    onMounted(() => {
      document.title = "Mülakat Sonuçları"; // Sayfa başlığını ayarla
    });

    return {
      answeredQuestions,
      totalTime,
      remainingTime,
      backToHome,
    };
  },
});
</script>
